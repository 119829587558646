<template>
	<div class="broadcastManage">
		<div class="filter-container" style="display: flex;align-items: center;margin-bottom: 10px;">
			<div>
				<div class="filter-item">
					<label class="label">订单编号:</label>
					<el-input v-model="orderNoKey" placeholder="请输入订单编号" @keyup.enter.native="goodsFilter" style="width: 250px;"
					clearable></el-input>
				</div>
				<div class="filter-item">
					<label class="label">售后编号:</label>
					<el-input v-model="aftersaleKey" placeholder="请输入售后编号" @keyup.enter.native="goodsFilter" style="width: 250px;"
					clearable></el-input>
				</div>
				<div class="filter-item">
					<label class="label">收款账户:</label>
					<el-input v-model="accountKey" placeholder="请输入收款账户" @keyup.enter.native="goodsFilter" style="width: 250px;"
					clearable></el-input>
				</div>
				<div class="filter-item">
					<label class="label">收款人姓名:</label>
					<el-input v-model="nameKey" placeholder="请输入收款人姓名" @keyup.enter.native="goodsFilter" style="width: 250px;" clearable></el-input>
				</div>
			</div>
		</div>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">选择时间:</label>
				<el-date-picker v-model="orderTimes" type="datetimerange" :picker-options="pickerOptions" range-separator="～"
				start-placeholder="开始日期" end-placeholder="结束日期" align="right" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
			</div>
			<div class="filter-item">
				<label class="label">打款状态:</label>
				<el-select v-model="orderType" style="width: 200px;margin-right: 10px;">
					<el-option v-for="item in orderTypeList" :key="item.value" :label="item.lable" :value="item.value"></el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsFilter">查询</el-button>
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container" style="margin-top:20px">
			<el-table :data="broadData" style="width: 100%;" v-loading="loading" ref="multipleTable">
				<el-table-column label="订单编号">
					<template slot-scope="scope">
						<div style="color: #409EFF;" @click="handleOrderDetailShow(scope.row)">{{scope.row.orderNo}}</div>
					</template>
				</el-table-column>
				<el-table-column label="售后编号">
					<template slot-scope="scope">
						<div style="color: #409EFF;" @click="handleDetailShow(scope.row)">{{scope.row.afterSaleCode}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="identity" label="收款账户"></el-table-column>
				<el-table-column prop="realName" label="收款人姓名"></el-table-column>
				<el-table-column prop="transDate" label="打款时间"></el-table-column>
				<el-table-column prop="transAmount" label="打款金额"></el-table-column>
				<el-table-column label="状态">
					<template slot-scope="scope">
						<div>
							{{scope.row.status == 0 || scope.row.status == 4 ? '打款中' : scope.row.status == 1 ? '打款成功' : '打款失败'}}
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="subMsg" label="失败原因"></el-table-column>
				<el-table-column prop="operator" label="售后操作人"></el-table-column>
				<!-- <el-table-column label="操作" width="240">
					<template slot-scope="scope">
						<buttonPermissions :datas="'recordDetial'">
							<span style="color:#02a7f0cc;" @click="toDetial(scope.row)">查看详情</span>
						</buttonPermissions>
					</template>
				</el-table-column> -->
			</el-table>

			<div style="text-align: right;">
				<el-pagination v-if="total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="pageNo" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				:total="total"></el-pagination>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		alipayRecord,
	} from '@/api/wyUsedInterface'
	import apiList from '@/api/other'
	import buttonPermissions from '@/components/buttonPermissions';

	export default {
		components: {
			buttonPermissions
		},
		data() {
			return {
				broadData: [],
				loading: false,
				total: 0, //总条数
				pageNo: 0, //当前页码
				pageSize: 20, //当前页显示数据条数
				orderNoKey: '',
				aftersaleKey: '',
				accountKey: '',
				nameKey: '',
				orderType: 0,
				orderTypeList: [{
						lable: '全部',
						value: 0
					},
					{
						lable: '打款中',
						value: 1
					},
					{
						lable: '打款成功',
						value: 2,
					},
					{
						lable: '打款失败',
						value: 3,
					},
				],
				orderTimes: '',
				pickerOptions: { //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
			}
		},
		created() {
			this.getList();
		},
		methods: {
			// 获取列表
			async getList() {
				console.log(12);
				var sTime = '';
				var eTime = '';
				if(this.orderTimes){
					sTime = this.orderTimes[0];
					eTime = this.orderTimes[1];
				}
				try {
					this.loading = true;
					let data = {
						pageNo: this.pageNo,
						pageSize: this.pageSize,
						afterSaleCode:this.aftersaleKey,
						orderNo:this.orderNoKey,
						realName:this.nameKey,
						identity:this.accountKey,
						startTime:sTime,
						endTime:eTime,
					}
					if(this.orderType>0){
						data.status=this.orderType-1;//打款状态 0：进行中 1：成功 2：失败
					}
					//返回数据中status 0，4是打款中  1是成功  2，3是失败
					let result = await alipayRecord(data);
					this.broadData = result.data.records || [];
					this.total = result.data.total;
				} finally {
					this.loading = false
				}

			},
			//查看详情
			toDetial(row) {
				this.$router.push({
					path: '/finance/record/detial?id=' + row.id + '&accountType=' + row.accountType
				});
			},
			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.pageNo = val;
				this.getList();
			},
			goodsFilter() {
				this.pageNo = 1;
				this.getList()
			},
			//跳转至详情
			handleDetailShow (row) {
				this.getafterSaleDetail(row.afterSaleId);
			},
			//跳转至详情
			handleOrderDetailShow (row) {
				let params = {
					Id: row.orderId ? row.orderId : 0
				}
				this.publicToDetails(params,'/order/orderDetailpage',this);
			},
			async getafterSaleDetail(id) {
				let res = await apiList.ordersupportDetail({
					afterSaleId: id
				})
				let params = {
					id: id
				}
				if(res.success){
					if (res.type == 3) {
						this.publicToDetails(params,'/order/refundAfterSale/exChangeRefundDetails',this);
					} else {
						this.publicToDetails(params,'/order/refundAfterSale/refundDetails',this);
					}
				}else{
					this.publicToDetails(params,'/order/refundAfterSale/refundDetails',this);
				}
			},
		}
	}
</script>

<style lang="less">
	.broadcastManage {
		width: 100%;
		background: #fff;
		padding: 15px;

		.el-card__body {
			padding-top: 0px !important;
		}

		.pointers {
			cursor: pointer;
		}

		input[type="number"]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.transfercontent {
			// justify-content: ;
			flex-wrap: wrap;

			.item_content {
				width: 25%;
				margin-top: 45px;

				.num {
					margin-top: 10px;
				}
			}
		}
	}
</style>
